import { cn } from "@/lib/utils";
import formatProgramPrice from "../utils/formatProgramPrice";
import { ProgramPricingData } from "@/lib/ecommerce/getProgramPricingData";

type ProgramPricingProps = {
  pricingData: Partial<ProgramPricingData>;
  originalPriceSize?: string;
  discountedPriceSize?: string;
  strikethroughClass?: string;
  discountedPriceClassName?: string;
  originalPriceClassName?: string;
};

export default function ProgramPricing({
  pricingData,
  strikethroughClass = "line-through",
  originalPriceSize,
  discountedPriceSize,
  discountedPriceClassName,
  originalPriceClassName,
}: ProgramPricingProps) {
  const { originalPrice, discountedPrice, currency } = pricingData;
  return (
    <>
      {discountedPrice && originalPrice !== discountedPrice ? (
        <div
          className={cn(
            `${discountedPriceSize ? `text-${discountedPriceSize}` : ""}`,
            discountedPriceClassName,
          )}
        >
          <span className="sr-only">
            {originalPrice ? "Discounted price:" : "Price:"}
          </span>
          {`${formatProgramPrice(discountedPrice, currency)}${!originalPrice ? currency : ""}`}
        </div>
      ) : null}
      {originalPrice && discountedPrice && originalPrice !== discountedPrice ? (
        // Both prices are available and different, show original with line-through
        <div
          className={cn(
            "opacity-50",
            strikethroughClass,
            `${originalPriceSize ? `text-${originalPriceSize}` : ""}`,
            originalPriceClassName,
          )}
        >
          <span className="sr-only">Original price:</span>
          {`${formatProgramPrice(originalPrice, currency)}${currency}`}
        </div>
      ) : originalPrice &&
        (!discountedPrice || originalPrice === discountedPrice) ? (
        // Only original price available OR prices are equal, show single price without line-through
        <div
          className={cn(
            `${originalPriceSize ? `text-${originalPriceSize}` : ""}`,
            originalPriceClassName,
          )}
        >
          <span className="sr-only">Price:</span>
          {`${formatProgramPrice(originalPrice, currency)}${currency}`}
        </div>
      ) : null}
    </>
  );
}
