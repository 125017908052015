"use client";
import { ProgramPricingData } from "@/lib/ecommerce/getProgramPricingData";
import { cn } from "@/lib/utils";
import { RefObject, useEffect, useState } from "react";
import ProgramPricing from "../ProgramDetailRefresh/ProgramPricing";
import { ProcessedCourse } from "@/lib/course/types";
import { Program } from "@/lib/program/types";
import ProgramEnrollButton from "../ProgramDetailRefresh/ProgramEnrollButton";
import SideRailTitle from "../ProgramDetailRefresh/SideRail/SideRailTitle";
import SideRailVideoPlayer from "../ProgramDetailRefresh/SideRail/SideRailVideoPlayer";
import SideRailRating from "../ProgramDetailRefresh/SideRail/SideRailRating";
import { CourseVideo } from "@/components/CourseContent/ContentOverview/types";

type ProgramHeroSideRailContainerProps = {
  scrollFollowerRef: RefObject<HTMLDivElement | null>;
  sittingStillRef: RefObject<HTMLDivElement | null>;
  scrollingLimitRef: RefObject<HTMLDivElement | null>;
  isFollowingScroll?: boolean;
  programIsEnrollable: boolean;
  pricingData: ProgramPricingData | null;
  courses: ProcessedCourse[];
  programData: Program;
  skus: string[];
};

export default function ProgramHeroSideRailContainer({
  scrollFollowerRef,
  sittingStillRef,
  scrollingLimitRef,
  isFollowingScroll = true,
  pricingData,
  courses,
  programData,
  skus,
  programIsEnrollable,
}: ProgramHeroSideRailContainerProps) {
  const [canRailFollowScroll, setCanRailFollowScroll] = useState(true);
  const { enrollmentCount, title } = programData;
  const programOverviewVideo =
    programData.video.length > 0
      ? (programData.video[0] as CourseVideo)
      : undefined;

  const scrollHandler = () => {
    const railContainerHeight = scrollFollowerRef.current?.offsetHeight ?? 0;
    const fixedContainerHight = sittingStillRef.current?.offsetHeight ?? 0;

    const lastSectionPosition =
      (scrollingLimitRef?.current?.getBoundingClientRect().top ??
        railContainerHeight) > 0
        ? (scrollingLimitRef?.current?.getBoundingClientRect().top ??
          railContainerHeight)
        : 0;
    const shouldNotScroll = railContainerHeight
      ? lastSectionPosition - railContainerHeight < railContainerHeight
      : lastSectionPosition - fixedContainerHight < fixedContainerHight;

    if (shouldNotScroll) {
      setCanRailFollowScroll(false);
      return;
    }
    setCanRailFollowScroll(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <div
      className={
        isFollowingScroll
          ? cn(canRailFollowScroll ? "hidden xl:block" : "hidden", "w-[430px]")
          : `${canRailFollowScroll ? "hidden" : "hidden xl:block"} w-[430px] self-end`
      }
    >
      <div
        ref={isFollowingScroll ? scrollFollowerRef : sittingStillRef}
        className={
          isFollowingScroll
            ? "shadow-hero-rail fixed w-[430px] rounded-xl"
            : "shadow-hero-rail rounded-xl"
        }
      >
        <div className="flex flex-col gap-4 p-4 bg-putty-300 rounded-t-xl">
          <SideRailTitle />
          <SideRailVideoPlayer title={title} video={programOverviewVideo} />
          <SideRailRating enrollmentCount={enrollmentCount} />
        </div>
        {/* TODO: replace div below with rail body components */}
        <div className="flex flex-col gap-4 p-4 bg-gradient-to-b from-[#D7D3C5] to-putty-300 rounded-b-xl">
          <div>Grow this skills</div>
          <div>Testimonial</div>
          <div>Partner</div>
          <div>Date</div>
          {/* Program price */}
          {pricingData ? (
            <div className="flex place-content-center font-bold w-full gap-1">
              <ProgramPricing pricingData={pricingData} />
            </div>
          ) : null}
          {/* Enroll CTA */}
          {programIsEnrollable &&
          programData.programEligibleForOneClickPurchase ? (
            <div>
              <ProgramEnrollButton
                programData={programData}
                courses={courses}
                skus={skus}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
