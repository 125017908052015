"use client";
import CourseRating from "@/components/CourseContent/CourseRating";
import { Badge } from "@/components/ui/badge";

type SideRailRatingProps = {
  count?: number;
  average?: number;
  enrollmentCount: number;
  showTrendingBadge?: boolean;
};

export default function SideRailRating({
  count,
  average,
  enrollmentCount,
  showTrendingBadge = false,
}: SideRailRatingProps) {
  return (
    <div className="flex flex-col gap-2">
      {count && average && (
        <div className="flex justify-between items-center">
          <CourseRating
            count={count}
            average={average}
            variant="solid"
            ratingClassName="text-gray-800"
          />
          {showTrendingBadge && (
            <Badge
              variant="primaryLight"
              className="font-normal whitespace-nowrap bg-secondary-500 border-putty-400 !rounded !shadow-none px-2"
            >
              Trending
            </Badge>
          )}
        </div>
      )}
      {enrollmentCount && (
        <div className="flex items-center">
          <span className="font-bold text-gray-800 m-0">
            {enrollmentCount.toLocaleString()}
          </span>
          <span className="m-0 text-gray-800">&nbsp;learners enrolled</span>
        </div>
      )}
    </div>
  );
}
