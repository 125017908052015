"use client";
import { CourseVideo } from "@/components/CourseContent/ContentOverview/types";
import VideoPlayer from "@/components/VideoPlayer";

type SideRailVideoPlayerProps = {
  title: string;
  video?: CourseVideo;
};

export default function SideRailVideoPlayer({
  title,
  video,
}: SideRailVideoPlayerProps) {
  return (
    <div>
      {video && video.src ? (
        <VideoPlayer
          videoClassName="w-full h-[243px]"
          videoContainerClassName="w-full h-[243px] bg-black rounded-xl"
          title={"Program Overview Video"}
          videoUrl={video?.src}
          isSideRail
        />
      ) : (
        <p className="text-primary-500 tracking-tight font-black text-3xl m-0">
          {title}
        </p>
      )}
    </div>
  );
}
