"use client";
import React from "react";
import { PartialPartner } from "@/lib/partner/types";
import Image from "next/image";
import Link from "next/link";
import { partnerImageDimensions } from "@/constants/hero/imageDimensions";
import { ProgramPricingData } from "@/lib/ecommerce/getProgramPricingData";
import { programMap } from "@/lib/program/constants";
import EmailOptIn from "@/components/EmailOptIn/EmailOptIn";
import useEmailOptIn from "../ProgramDetailContext/hooks/useEmailOptIn";
import ProgramEnrollButton from "../ProgramEnrollButton";
import type { ProcessedCourse } from "@/lib/course/types";
import type { Program } from "@/lib/program/types";
import formatProgramPrice from "../utils/formatProgramPrice";
import Icon from "@/components/icons";
import useProgramRedesignTraffic from "@/monarch/rules/programRedesignTraffic/useProgramRedesignTraffic";
import { Skeleton } from "@/components/ui/skeleton";
import useCertificateCTAText from "@/monarch/rules/certificateCTA/useCertificateCTAText";
import ProgramPricing from "../ProgramPricing";

export interface ProgramHeaderProps {
  courses: ProcessedCourse[];
  programData: Program;
  skus: string[];
  pricingData: ProgramPricingData | null;
  duration: string;
  programIsEnrollable: boolean;
}

const ProgramHeaderLoader: React.FC = () => (
  <div className="bg-white rounded-xl py-6 px-4 sm:p-10 md:flex md:items-start md:justify-between">
    <div className="flex flex-col gap-5 w-full">
      <div>
        <div className="flex justify-center sm:justify-start">
          <Skeleton className="h-16 w-40 rounded" />
        </div>

        <Skeleton className="h-4 w-32 mt-4" />

        <Skeleton className="h-8 w-60 mt-2" />
      </div>

      <div className="[&_p]:m-0 flex gap-2">
        <Skeleton className="h-6 w-20" />
        <Skeleton className="h-6 w-16" />
      </div>

      <div className="flex xl:justify-end gap-2">
        <Skeleton className="h-5 w-5 rounded-full" />
        <Skeleton className="h-5 w-40" />
      </div>

      <div className="flex items-baseline w-full gap-2 justify-end">
        <Skeleton className="h-10 w-32" />
        <Skeleton className="h-10 w-40" />
      </div>

      <div className="hidden md:flex justify-start items-center">
        <Skeleton className="h-5 w-80" />
      </div>
    </div>
  </div>
);

const ProgramHeader = ({
  courses,
  programData,
  skus,
  pricingData,
  duration,
  programIsEnrollable,
}: ProgramHeaderProps) => {
  const { isLoading: isHeaderLoading, headerCta } = useProgramRedesignTraffic();
  const { emailOptIn, setEmailOptIn } = useEmailOptIn();
  const supTitle =
    programMap[programData.productType]?.type || programMap["default"].type;

  const isRetiringSoon = programData.customizations?.catalogPage?.retiringSoon;
  const retiringSoonText =
    programData.customizations?.catalogPage?.retiringSoonTextOverwrite ||
    "This Program is retiring soon";

  if (isHeaderLoading) {
    return <ProgramHeaderLoader />;
  }

  return (
    <div className="bg-white rounded-xl py-6 px-4 sm:p-10 md:flex md:items-start md:justify-between">
      <div className="flex flex-col gap-5 w-full">
        <div>
          <div className="flex justify-center sm:justify-start">
            {programData.partners.map((partner: PartialPartner) => {
              return partner.showOnSchoolsAndPartnersPage ? (
                <Link
                  className="d-inline-block"
                  key={partner.key}
                  href={`/school/${partner.slug}`}
                >
                  <Image
                    src={partner.logoUrl || ""}
                    width={partnerImageDimensions.width}
                    height={partnerImageDimensions.height}
                    alt={programData.title || ""}
                    title={programData.title || ""}
                    key={partner.key}
                    className="max-w-[165px] md:max-w-full"
                  />
                </Link>
              ) : (
                <Image
                  src={partner.logoUrl || ""}
                  width={partnerImageDimensions.width}
                  height={partnerImageDimensions.height}
                  alt={programData.title || ""}
                  title={programData.title || ""}
                  key={partner.key}
                  className="max-w-[165px] md:max-w-full"
                />
              );
            })}
          </div>
          <p className="mb-5 md:mb-4 text-sm md:text-base"> {supTitle}</p>
          <h1 className="m-0 p-0 tracking-tight" style={{ fontWeight: 900 }}>
            {programData.title}
          </h1>
        </div>

        <div className="[&_p]:m-0 flex">
          <a href="#courses">
            <p className="text-lg md:text-2xl underline font-bold">
              {programData.courses.length} courses
            </p>
          </a>
          <p className="text-lg md:text-2xl font-bold">, {duration}</p>
        </div>

        {isRetiringSoon && (
          <div className="flex xl:justify-end gap-2 text-primary">
            <Icon type="warning" />
            <p className="m-0 text-black">{retiringSoonText}</p>
          </div>
        )}

        <div
          className={`flex items-baseline w-full gap-2 ${
            headerCta?.pillPosition !== "left" ? "justify-end" : "justify-start"
          }`}
        >
          {pricingData && (
            <div className="flex align-items-center justify-center hidden md:flex gap-2 text-lg text-gray-800">
              <ProgramPricing
                pricingData={pricingData}
                strikethroughClass="line-through text-gray-500 opacity-100"
              />
            </div>
          )}
          {programIsEnrollable &&
            programData.programEligibleForOneClickPurchase && (
              <div className="flex flex-col md:flex-row gap-4 sm:gap-2 items-center bg-putty-100 p-4 rounded-xl border-putty-400 border w-full md:w-auto">
                <ProgramEnrollButton
                  courses={courses}
                  programData={programData}
                  skus={skus}
                />
              </div>
            )}
        </div>
        <div className="hidden md:flex justify-start items-center">
          <EmailOptIn
            title={programData.title || ""}
            partners={programData.partners}
            optIn={emailOptIn}
            variant="secondary"
            toggleEmailOptIn={() => {
              setEmailOptIn(!emailOptIn);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramHeader;
