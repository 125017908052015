"use client";
import Icon from "@/components/icons";

type SideRailTitleProps = {
  text?: string;
  iconType?: string;
};

export default function SideRailTitle({
  text = "Earn Certificate",
  iconType = "card-membership",
}: SideRailTitleProps) {
  return (
    <div className="flex justify-center gap-2">
      <Icon type={iconType} svgProps={{ width: 32, height: 32 }} />
      <p className="text-primary-500 m-0 font-bold">{text}</p>
    </div>
  );
}
